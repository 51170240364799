/** @jsx jsx */
import { jsx } from "theme-ui";

const Hero = () => (
  //   <div
  //     sx={{
  //       display: "flex",
  //       alignItems: "center",
  //       width: ["90%", "60%"],
  //       height: "70vh",
  //       bg: "white",
  //       borderRadius: 10,
  //       border: "solid 1px",
  //       borderColor: "red",
  //       boxShadow: "1px 2px 1px red",
  //     }}
  //   >
  //     <div sx={{ width: 300, bg: "gray" }}>
  //       <h3>HEADLESS</h3>
  //     </div>
  //   </div>

  <div
    sx={{
      bg: "#fff",
      width: ["90vw", "50vw"],
      height: "80vh",
      my: 3,
      border: "1px solid #adadad",
      borderRadius: 8,
      boxShadow: "0px 0px 20px #acacac",
      display: "grid",
    }}
  >
    <div
      sx={{
        background: "linear-gradient(to top, #ebebeb, #d5d5d5)",
        color: "#4d494d",
        fontSize: "11pt",
        lineHeight: "20px",
        textAlign: "center",
        width: "100%",
        height: "20px",
        borderTop: "1px solid #f3f1f3",
        borderBottom: "1px solid lightgrey",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        userSelect: "none",
        fontFamily: "Cabin",
      }}
    >
      <div
        sx={{
          paddingLeft: "8px",
          paddingTop: "3px",
          float: "left",
          lineHeight: "0px",
        }}
      >
        <div
          sx={{
            background: "#ff5c5c",
            fontSize: "9pt",
            width: "11px",
            height: "11px",
            border: "1px solid #e33e41",
            borderRadius: "50%",
            display: "inline-block",
          }}
        ></div>
        <div
          sx={{
            background: "#ffbd4c",
            mx: 2,
            fontSize: "9pt",
            width: "11px",
            height: "11px",
            border: "1px solid #e09e3e",
            borderRadius: "50%",
            display: "inline-block",
          }}
        ></div>
        <div
          sx={{
            background: "#00ca56",
            // mx: 2,
            fontSize: "9pt",
            width: "11px",
            height: "11px",
            border: "1px solid #14ae46",
            borderRadius: "50%",
            display: "inline-block",
          }}
        ></div>
      </div>
      <p sx={{ m: 0, mx: 6 }}>{`< devz / >`}</p>
    </div>
    <div sx={{ mx: 2 }}>
      <h1 sx={{ fontFamily: "Poppins", m: 0 }}>Bra på data</h1>

      <button
        sx={{
          appearance: "none",
          bg: "hotpink",
          border: "solid 1px white",
          p: 3,
          color: "white",
          borderRadius: 9,
        }}
      >
        <a
          href="mailto:mattias@devz.se"
          sx={{ textDecoration: "none", color: "white" }}
        >
          <h2 sx={{ fontFamily: "Cabin", m: 0 }}>Kontakt</h2>
        </a>
      </button>
    </div>
  </div>
);

export default Hero;
