/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";

const IndexPage = () => (
  <Layout>
    <SEO title="devz" />
    <Hero />
  </Layout>
);

export default IndexPage;
